import axios from "axios";
import "../config.js";

const getAccess = () => {
  var clientkeys = {};
  if (`${process.env.REACT_APP_DEVELOPMENT_ENV}` === "staging") {
    clientkeys = {
      client_name: `${process.env.REACT_APP_NAME_STAGING}`,
      client_secret: `${process.env.REACT_APP_CLIENT_SECRET_STAGING}`,
    };
  } else if (`${process.env.REACT_APP_DEVELOPMENT_ENV}` === "production") {
    clientkeys = {
      client_name: `${process.env.REACT_APP_NAME_PRODUCTION}`,
      client_secret: `${process.env.REACT_APP_CLIENT_SECRET_PRODUCTION}`,
    };
  }
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${window.livasaConfig.token}${window.livasaConfig.networkid}`,
      headers: {
        "Content-Type": "application/json",
      },
      data:JSON.stringify(clientkeys),
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res);
        }
         localStorage.setItem("token",res.data.access_token);
        return resolve(res.data.access_token);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

const getToken = () => {
  try {
    let data = localStorage.getItem("token");
    return data;
  } catch (e) {
    return e;
  }
};


const getCategoriesBasedOnParentId = () => {
  return new Promise(async (resolve, reject) => {
    try {
      let token = await getToken(); // Wait for token
      const res = await axios({
        method: "GET",
        url: `${window.livasaConfig.getCategories}${window.livasaConfig.networkid}/${window.livasaConfig.networkid}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status !== 200) {
        reject(new Error("Failed to fetch categories"));
      } else {
        resolve(res.data.categoryList);
      }
    } catch (error) {
      reject(error);
    }
  });
};



const productModelBasedonSubcategory = (getCatId, Type, user) => {
  return new Promise(async (resolve, reject) => {
    try {
      let token = await getToken(); 
      const res = await axios({
        method: "GET",
        url: `${window.livasaConfig.modelBasedOnCategory}${window.livasaConfig.networkid}/${window.livasaConfig.networkid}/${user}/${getCatId}/${Type}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          orgid: user,
          networkid: `${window.livasaConfig.networkid}`,
          username: user,
          suborgid: "",
        },
      });

      if (res.status !== 200) {
        return reject(res);
      }
      return resolve(res.data.products);
    } catch (error) {
      reject(error);
    }
  });
};

const productLikeCount = (product) => {
  return new Promise((resolve, reject) => {
    axios({
      method: "post",

      url: `${window.livasaConfig.productLikeCount}${window.livasaConfig.networkid}/${product.consumeradminid}/${product.businessid}/${product.productid}`,

      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${getToken()}`,
        networkid: `${window.livasaConfig.networkid}`,
        orgid: `${product.businessid}`,
        suborgid: "",
        username: `${product.businessid}`,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          return reject(res.data);
        }
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err);
      });
  });
};



const getSubCategories = (username, catid) => {
  return new Promise(async (resolve, reject) => {
    try {
      let token = await getToken(); 
      const res = await axios({
        method: "GET",
        url: `${window.livasaConfig.getSubCategories}${window.livasaConfig.networkid}/${window.livasaConfig.networkid}/${username}/${catid}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (res.status !== 200) {
        return reject(res.data);
      }
      return resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};


const getStoreLocation = (type) =>{
  return new Promise(async(resolve, reject) =>{
    try{
      let token = await getToken();
      let url = `${window.livasaConfig.getLoaction}${window.livasaConfig.networkid}/${window.livasaConfig.user}/${type}`
    const res = await axios({
      method:"GET",
      url:url,
      headers:{
        "Content-Type":"application/json",
        Authorization: `Bearer ${token}`
      }
    })
    if(res !== 200){
      return reject(res.data)
    }
    return resolve(res.data)
    }
    catch(err){
      console.log("jjj")
    }

  })
}



export default {
  getAccess,
  getCategoriesBasedOnParentId,
  productModelBasedonSubcategory,
  productLikeCount,
  getSubCategories,
  getStoreLocation
};
