import React, { useState, useEffect, useMemo } from "react";
import soap from "../../assets/images/soap-honey-comb-arrangement-high-angle.jpg";
import honey from "../../assets/images/honey.jpg";
import shilajit from "../../assets/images/279.jpg";
import Title from "../../components/titles/Title";
import FilterSort from "../../components/FilterSort/FilterSort";
import { Circles } from "react-loader-spinner";

import { useParams } from "react-router-dom";
import MainService from "../../common/MainService";
import { NavHashLink } from "react-router-hash-link";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
export default function Honey() {
  const { subId } = useParams();
  const [getCatId, setCatId] = useState("");
  const [loding, setLoading] = useState(true);
  const [getHoneyProducts, setHoneyProducts] = useState([]);
 
  const [subCategory, setSubCategory] = useState([]);
  const [getSubCategoryValue, setSubCategoryValue] = useState("Livasa-Natural-Honey");

  useMemo(() => {
    setSubCategoryValue(subId);
  }, []);

  useEffect(() => {
    getAllSubCategory();
   
  }, []);



  useEffect(() => {
    if (!getCatId || getCatId === "" || getSubCategoryValue === "") return;
    getProductsHandler();
    getAllSubCategory()
  }, [getCatId, getSubCategoryValue]);



  const getCategoriesHandler = () => {
    MainService.getCategoriesBasedOnParentId()
      .then((res) => {
        const catFilter = res.filter((resdata, index) => {
          return resdata.categoryname === "PROCESSED-PRODUCTS";
        });
        setCatId(catFilter[0].categoryid);
      })
      .catch((err) => {
        console.log("errrrrrrrrrrrrrrrrrrrrrr", err);
      });
  };

  useMemo(() => {
    getCategoriesHandler();
  }, []);

  const getProductsHandler = () => {
    MainService.productModelBasedonSubcategory(getCatId, getSubCategoryValue,user)
      .then((res) => {
        setHoneyProducts(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log("rrrrrrrrrrrrsssssssss", err);
      });
  };
  const user = `${window.livasaConfig.user}`

  const getAllSubCategory = () => {
    MainService.getSubCategories(user,getCatId)
      .then((res) => {
        setSubCategory(res.contentsubcategories);
      })
      .catch((e) => {
        console.log("err");
      });
  };

  const subCatHandler = (val) => {
    setSubCategoryValue(val);
  };

  const subcategoryImageMapping = {
    "Livasa-Honey": honey,
    "Livasa-Soap": soap,
    "Livasa-shampoo": shilajit,
  };
  const defaultImageUrl = honey;
  return (
    <>
      <div className="container collection-container">
        <Title heading="Collections" classname="mb-4" />
        <Row className="">
                      {loding === false ? <>
            {subCategory.map((data) => {
              const imageUrl =
                subcategoryImageMapping[data.contentsubcategoryname] ||
                defaultImageUrl;
              return (
                <Col md={6} lg={3}>
                  <NavHashLink
                    key={data.contentsubcategoryname}
                    to={`/collections/products/${data.contentsubcategoryname}/#products`}
                  >
                    <div className="card-container">
                      <div className="card-image-container mb-4">
                        <img src={imageUrl} alt={data.contentsubcategoryname} />
                      </div>
                      <div>
                        <h5 className="text-center">
                          {data.contentsubcategoryname}
                        </h5>
                      </div>
                    </div>
                  </NavHashLink>
                </Col>
              );
            })}</> : <div className="justify-content-center d-flex">
            <Circles
              type="TailSpin"
              color="orange"
              height={70}
              width={70}
              timeout={5000}
            />
          </div>}
          </Row>
        <div className="pt-5" id="products"></div>
        <Title heading="Products" classname="mb-5 pt-5" />
        <FilterSort obj={getHoneyProducts} loadValue={loding} />
      </div>
    </>
  );
}
